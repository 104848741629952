<template>
    <div class="selectCourse">
        <el-button :type="type" icon="el-icon-plus" @click="handleDialogShow">
            <slot name="content">{{content}}</slot>
        </el-button>
        <el-dialog :title="dialogTitle" :width="dialogWidth" :visible.sync="dialogVisible" :close-on-click-modal="false"
            @close="handleDialogClose" custom-class="course-select-manage-dialog">
            <div class="dialog-content">
                <!-- <div class="oper-container">
                <div class="search">
                    <el-input class="input" v-model="search.name" placeholder="请输入课程名称" clearable @keydown.enter="pageChange(1)"/>
                    <el-button type="primary" @click="pageChange(1)">查询</el-button>
                </div>
                <el-dropdown>
                    <el-button type="primary" icon="el-icon-plus">新建课程</el-button>
                    <el-dropdown-menu slot="dropdown" class="special-column-manage-dropdown">
                        <el-dropdown-item class="add-item">
                            <div @click="routerChange(COURSE.TYPE.TEXT)">图文</div>
                        </el-dropdown-item>
                        <el-dropdown-item class="add-item">
                            <div @click="routerChange(COURSE.TYPE.AUDIO)">音频</div>
                        </el-dropdown-item>
                        <el-dropdown-item class="add-item">
                            <div @click="routerChange(COURSE.TYPE.VIDEO)">视频</div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div> -->
                <div class="tabs-container">
                    <el-tabs class="my-tabs" v-model="search.media_type" @tab-click="handleTabsChange">
                        <el-tab-pane label="图文" :name="COURSE.TYPE.TEXT + ''"></el-tab-pane>
                        <el-tab-pane label="音频" :name="COURSE.TYPE.AUDIO + ''"></el-tab-pane>
                        <el-tab-pane label="视频" :name="COURSE.TYPE.VIDEO + ''"></el-tab-pane>
                        <el-tab-pane label="专栏" :name="COURSE.TYPE.SPECIAL_COLUMN + ''" v-if="showColumn"></el-tab-pane>
                    </el-tabs>
                </div>
                <div class="table-container">
                    <div class="table-search">
                        <el-input
                            class="circular-bead-select"
                            v-model="search.name"
                            clearable
                            placeholder="请选择名称"
                            @change="pageChange(1)">
                        </el-input>
                        <el-select
                            class="circular-bead-select"
                            v-model="search.status"
                            clearable
                            placeholder="请选择状态"
                            @change="pageChange(1)">
                            <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                       :value="item.value" />
                        </el-select>
                    </div>
                    <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                        ref="multipleTable" :show-header='false' :cell-style="{height: 'auto',padding: '5px 0'}"
                        :row-key="(row) => row.id" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column label="单品名称" min-width="200" :show-overflow-tooltip="true">
                            <div slot-scope="scope" class="course-name">
                                <div class="course-cover">
                                    <el-image :src="IMG_URL_PREFIX + scope.row.cover" fit='cover'
                                        class="course-cover-img" v-if="scope.row.cover">
                                        <div slot="error" class="error iconfont icon-p-image"></div>
                                    </el-image>
                                    <div class="error iconfont icon-p-image" v-else></div>
                                </div>
                                {{scope.row.name}}
                            </div>
                        </el-table-column>
                        <el-table-column label="状态" min-width="120" align="right">
                            <template slot-scope="scope">
                                <i class="iconfont icon-point"
                                    :class="scope.row.status | courseStatusFilter('class')" />
                                {{scope.row.status | courseStatusFilter}}
                            </template>
                        </el-table-column>
                        <div class="" slot="empty">
                            <no-data></no-data>
                        </div>
                    </el-table>
                </div>
                <div class="page-container">
                    <el-pagination layout="prev, pager, next" background @current-change="pageChange"
                        :current-page="currPage" :total="total" :page-size="10">
                    </el-pagination>
                </div>
            </div>
            <div slot="footer" class="dialog-footer" style="display: flex;justify-content: space-between;">
                <el-dropdown placement='top'>
                    <el-button type="primary" icon="el-icon-plus" style="padding: 0 14px;">新建课程</el-button>
                    <el-dropdown-menu slot="dropdown" class="special-column-manage-dropdown">
                        <el-dropdown-item class="add-item">
                            <div @click="routerChange(COURSE.TYPE.TEXT)">图文</div>
                        </el-dropdown-item>
                        <el-dropdown-item class="add-item">
                            <div @click="routerChange(COURSE.TYPE.AUDIO)">音频</div>
                        </el-dropdown-item>
                        <el-dropdown-item class="add-item">
                            <div @click="routerChange(COURSE.TYPE.VIDEO)">视频</div>
                        </el-dropdown-item>
                        <el-dropdown-item class="add-item" v-if="showColumn">
                            <div @click="routerChange(COURSE.TYPE.SPECIAL_COLUMN)">专栏</div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div class="dialog-footer">
                    <el-button type="primary" size="medium" @click="handleDialogConfirm">确定</el-button>
                    <el-button class="cancel" size="medium" @click="dialogVisible = false">取消</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core';
const COURSE = Core.Const.COURSE;
import { deepCopy } from '@/core/utils';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        items: {
            type: Array
        },
        type: {
            type: String,
            default: 'primary'
        },
        content: {
            type: String,
            default: '添加内容'
        },
        dialogTitle: {
            type: String,
            default: '添加专栏内容'
        },
        dialogWidth: {
            type: String,
            default: '720px'
        },
        showColumn: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            COURSE,
            IMG_URL_PREFIX: Core.Const.NET.FILE_URL,
            currPage: 1,
            total: 0,
            search: {
                media_type: COURSE.TYPE.TEXT + '',
                status: '',
                name: '',
            },
            statusList: Core.Const.COURSE.STATUS_LIST,
            tableData: [],
            multipleSelection: [],

            dialogVisible: false
        };
    },
    watch: {
        items: function (newVar) {
            console.log('newVar', newVar);
            // this.multipleSelection = deepCopy(newVar);
            if (newVar.length > 1) {
                if (newVar) {
                    newVar.forEach((row) => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            }
        }
    },
    computed: {},
    mounted() {
        this.getTableData();
    },
    methods: {
        getTableData() {
            Core.Api.Course.list(
                this.currPage,
                10,
                this.search.media_type,
                '',
                this.search.status || -1,
                '',
                this.search.name,
            ).then((res) => {
                console.log('getDialogTableData res:', res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        handleTabsChange() {
            this.currPage = 1;
            this.getTableData();
        },
        pageChange(page) {
            this.currPage = page;
            this.getTableData();
        },
        routerChange(type, item) {
            console.log('routerChange type:', type);
            switch (type) {
                case COURSE.TYPE.TEXT:
                case COURSE.TYPE.AUDIO:
                case COURSE.TYPE.VIDEO:
                case COURSE.TYPE.SPECIAL_COLUMN:
                    this.$router.push({
                        path: '/online-course/course-edit',
                        query: {
                            type: type
                        }
                    });
                    break;
            }
        },
        handleSelectionChange(val) {
            console.log('handleSelectionChange val:', val);
            this.multipleSelection = val;
        },
        handleDialogShow() {
            this.dialogVisible = true;
            this.getTableData();
        },
        handleDialogClose() {
            this.dialogVisible = false;
        },
        handleDialogConfirm() {
            let ids = this.multipleSelection.map((item) => item.id);
            console.log('handleDialogConfirm ids:', ids);
            this.$emit('submit', { ids: ids, items: this.multipleSelection });
            this.dialogVisible = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.course-select-manage-dialog {
    .dialog-content {
        .oper-container {
            @include flex(row, space-between, center);
            margin-bottom: 20px;
            .search {
                @include flex(row, flex-start, center);
                .input {
                    width: 240px;
                    margin-right: 20px;
                }
            }
            .add-item {
                width: 100px;
            }
        }
        .table-container {
            margin-top: 0;
            max-height: 35vh;
            overflow-y: auto;
            .table-search {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 15px;
                margin-bottom: 15px;
                .circular-bead-select {
                    width: 25%;
                    margin-left: 15px;
                }
            }
            .course-name {
                // min-height: 70px;
                padding: 15px 0;
                display: flex;
                align-items: center;
                color: #363d42;
            }
            .course-cover {
                width: 40px;
                height: 30px;
                background: #dee5ea;
                border-radius: 1px;
                overflow: hidden;
                margin-right: 8px;
                text-align: center;
                line-height: 30px;
                .course-cover-img {
                    width: 100%;
                    height: 100%;
                }
                .error {
                    font-size: 14px;
                }
            }
            .error {
                // background-color: transparent;
                opacity: 0.3;
            }
        }
        .page-container {
            display: flex;
            justify-content: center;
            padding-top: 15px;
        }
    }
}
</style>

<style lang="scss">
.course-select-manage-dialog {
    .tabs-container {
        .my-tabs {
            .el-tabs__content {
                padding: 0;
            }
        }
    }
}
.course-select-manage-dialog {
    .add-item {
        width: 70px;
        padding: 5px 15px;
        text-align: center;
    }
}
</style>
